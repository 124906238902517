@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    color: #202124;
    background: #fafafa;
}

body,
html {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    width: 100%;
}

* {
    box-sizing: border-box;
}

#st-1 > div.st-btn.st-first {
    background: #5667ff;
    border-color: #5667ff;
}

#st-1 > div.st-btn.st-first > span {
    color: #fff;
}

#st-1 > div.st-btn.st-first > img {
    filter: grayscale(0);
    display: none;
}
